body.public-ui .block.booking-button,
body.cms-ui .block.bookingButton {
  .btn.btn-outline-primary {
    padding: 12px 24px;
    font-size: 16px;
    white-space: initial;
    text-decoration: none;
    color: var(--primary);
    -webkit-box-shadow: inset 0 0 0 1px var(--primary);
    box-shadow: inset 0 0 0 1px var(--primary);
    border-radius: 4px;
    font-weight: 600;
    font-family: var(--font-family);
    background-color: white;
    display: inline-block;

    &.isEditMode {
      pointer-events: none;
    }

    &:hover {
      color: var(--primary-hover);
      -webkit-box-shadow: inset 0 0 0 1px var(--primary-hover);
      box-shadow: inset 0 0 0 1px var(--primary-hover);
    }
  }
}
