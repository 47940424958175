.it-nav-wrapper {
  .it-header-center-wrapper {
    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        a {
          .icon {
            width: auto;
          }
        }
      }
    }
  }
}
