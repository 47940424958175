//mobile
.it-header-center-wrapper {
  // height: $dvt-header-center-max-height;

  .it-header-center-content-wrapper {
    //right zone
    .it-right-zone {
      .it-socials {
        ul {
          .icon {
            color: $primary-text;
          }
        }
      }

      .it-search-wrapper {
        a.search-link {
          border: $header-center-bg-color 2px solid !important;

          &:focus {
            outline: 2px solid $outer-focus-outline !important;
            outline-offset: 2px;

            border: none !important;
            box-shadow: 0 0 0 2px $inner-focus-shadow !important;
          }
        }
      }
    }
  }

  @media (max-width: map-get($map: $grid-breakpoints, $key: 'lg') - 1px) {
    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        .header-logo {
          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      .it-right-zone .it-search-wrapper a.rounded-icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
    }
  }
}
