.cms-ui {
  .field-wrapper-booking_types
    .data-grid-widget
    .ui.grid
    > .row
    .column
    > label[for='field-hidden'] {
    display: none;
  }

  .inline.field.help.field-wrapper-pause_table {
    border-bottom: 0;

    .data-grid-widget {
      .ui.grid .row .one.wide.column.term-actions {
        position: unset;
      }
      .stretched.row {
        flex-wrap: nowrap;
      }
    }
  }
}
