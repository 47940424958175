.it-footer {
  a {
    &:hover {
      color: transparentize($color: white, $amount: 0.15) !important;
    }
  }

  .it-footer-main {
    background-color: $footer-bg-color;

    .footer-logo {
      margin: 1rem 1rem 1rem 0;
    }
  }
}
