/* italia-design style */
// @import 'design-comuni-plone-theme/theme/site-variables';

/* bootstrap variables */
// @import 'bootstrap-italia/src/scss/functions';
// @import 'bootstrap-italia/src/scss/utilities/colors_vars';
// @import 'bootstrap-italia/src/scss/variables';
// @import 'design-comuni-plone-theme/theme/bootstrap-override/custom_variables';
// @import 'bootstrap/scss/functions';

/* site */
@import 'cms';
@import 'print';

/* Form sections */
$section-bg-grey: #ebeef0 !default;
$padding-x-section: 1.78rem !default;
$primary-hover: color-hover($primary) !default;
$card-bg-grey: #ebeef0 !default;

/* Buttons */
$min-width-button: 8.89rem !default;

/* override variables - queste variabili sono viste in tutto l'addon anche se non vengono importate nel file del singolo componente */
/* XXX: i nomi delle variabili (almeno alcuni) sono troppo generici per essere messi qui */
:root {
  --primary: #{$primary};
  --primary-hover: #{$primary-hover};
  --primary-rgb: #{red($primary)}, #{green($primary)}, #{blue($primary)};
  --danger: #{$danger};
  --font-family: #{$font-family-sans-serif};
  --font-size-sm: #{$font-size-sm};
  --section-bg-grey: #{$section-bg-grey};
  --padding-x-section: #{$padding-x-section};
  --min-width-button: #{$min-width-button};
  --input-disabled-bg: #{$input-disabled-bg};
  --card-bg-grey: #{$card-bg-grey};
}

.cms-ui {
  .field-wrapper-week_table_overrides {
    // hide the first column (the one with the label)
    // and strech the second column to fill the space
    > .ui.grid > .stretched.row {
      > .four.wide.column {
        display: none !important;
      }
      > .eight.wide.column {
        width: 100% !important;
      }
    }
  }

  .field-wrapper-week_table {
    // hide the first column (the one with the label)
    // and strech the second column to fill the space
    > .ui.grid > .stretched.row {
      > .four.wide.column {
        display: none !important;
      }
      > .eight.wide.column {
        width: 100% !important;
      }
    }

    .data-grid-widget {
      .row .column.field-column {
        &:nth-child(2) {
          flex-basis: calc(100% - 54px) !important;
        }
        &:nth-child(6) {
          flex-grow: 0;
        }
      }
    }

    // XXX: temporary fix
    #field-day {
      pointer-events: none;
      background: inherit;
    }

    // XXX: temporary fix
    button.delete-term,
    div.bottom-buttons {
      display: none !important;
    }
  }

  .field-wrapper-pause_table {
    .data-grid-widget {
      .row .column.field-column {
        &:first-child {
          flex-basis: calc(100% - 54px) !important;
        }
        &:nth-child(3) {
          flex-grow: 0;
        }
      }
    }
  }
}
