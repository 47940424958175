.it-footer {
  .it-footer-main {
    background-color: $primary-a8;
    .it-brand-wrapper {
      a {
        .icon {
          width: auto;
        }
      }
    }
  }
  .it-footer-small-prints {
    background-color: $primary-a10;
  }
}
