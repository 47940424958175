@media print {
    .public-ui .modal-backdrop.show {
        opacity: 1;
        background-color: white;
    }

    .modal.fade.show {
        overflow: hidden;
        zoom: 110%;
    }

    body.public-ui:has(div.modal-backdrop.show) #main {
        .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper {
            padding-left: 0 !important;
        }

        .it-header-wrapper {
            z-index: 9999;
        }

        main {
            display: none;
        }
    }
}
